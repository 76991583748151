<template>
  <div>
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner" align-v="end">
        <b-link class="brand-logo">
          <logo />
          <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
        </b-link>
        <b-navbar-nav class="nav align-items-center ml-auto absolute-locale">
          <locale></locale>
        </b-navbar-nav>
      </b-row>
    </div>

    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <b-card class="mb-0">
          <h3 class="brand-text text-primary ml-1 text-center">
            {{ $t("verifyOtp.title") }}
          </h3>
          <b-card-text class="mb-2">
            {{ $t("verifyOtp.subTitle") }}
          </b-card-text>
          <validation-observer ref="forgotPasswordForm">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <n-input :fields="fields" v-model="data">
                <template #verifyCode="item">
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="login-password">
                        {{ $t(item.field.label) }}
                      </label>
                      <b-link @click="resendOtp" v-if="isCanResendOtp">
                        <small>
                          {{ $t("button.resendOtp") }}
                        </small>
                      </b-link>
                      <small v-else>{{
                        $tc("button.resendOtpIn", countDown, {
                          value: countDown,
                        })
                      }}</small>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="item.field.label"
                      :rules="item.field.rules"
                    >
                      <b-form-input
                        v-model="data[item.field.key]"
                        :ref="item.field.key"
                        :id="item.field.key"
                        :name="item.field.key"
                        :placeholder="$t(item.field.label)"
                        :errors="errors"
                        :disabled="item.field.disabled"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </n-input>

              <n-button-loading
                type="submit"
                variant="primary"
                :loading="loading"
                block
              >
                {{ $t("button.verify") }}
              </n-button-loading>
            </b-form>
          </validation-observer>

          <p class="text-right mt-1 mb-0">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t("general.backToLogin") }}
            </b-link>
          </p>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Logo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BNavbarNav,
  BSpinner,
} from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import NButtonLoading from "@/components/NButtonLoading";
import Locale from "@/layouts/components/app-navbar/LocaleDropdown.vue";
import Repository from "@/repositories/RepositoryFactory";

const ForgotPasswordRepository = Repository.get("forgotPassword");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCardTitle,
    BCardText,
    BNavbarNav,
    BSpinner,
    Logo,
    Locale,
    NInput,
    NButtonLoading,
  },
  data() {
    return {
      isCanResendOtp: false,
      countDown: this.$route.query.valid,
      data: {
        username: this.$route.query.username,
        verifyCode: null,
      },
      loading: false,
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    if (!this.data.username || !this.countDown) {
      this.$router.push({
        name: "forgot-password",
      });
    }
    this.startCountOtp();
  },
  methods: {
    startCountOtp() {
      let self = this;
      let interval = setInterval(function () {
        if (self.countDown <= 0) {
          clearInterval(interval);
          self.isCanResendOtp = true;
          self.countDown = 60;
        }

        self.countDown = self.countDown - 1;
      }, 1000);
    },
    resendOtp() {
      if (!this.isCanResendOtp || this.loading) {
        return false;
      }

      this.loading = true;
      ForgotPasswordRepository.requestOtp({ ...this.data })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.isCanResendOtp = false;
            this.countDown = data.resendOtpSecond;
            this.startCountOtp();
          }
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;

      ForgotPasswordRepository.verifyOtp({ ...this.data })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.$router.push({
              name: "reset-password",
              query: {
                token: data.token,
                username: this.data.username,
              },
            });
          }
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    const fields = FormInput;

    return {
      appName,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
